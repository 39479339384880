import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import Steelframe from '../assets/steelframe.svg';
import metodBim from '../assets/metodologiaBIM.svg';
import disenhoCalculo from '../assets/disenho_calculo.svg';
import { motion } from 'framer-motion';


const teamServices = [
  {
    name: 'Diseño y Cálculos',
    title: `Utilizamos softwares avanzados cumpliendo con todas las normativas vigentes para asegurar la seguridad y estabilidad de las estructuras.`,
    education:`
    Nuestros servicios incluyen:`,
    educationText:`
    • Cálculos de hormigón armado
    • Cálculos de estructuras metálicas
    • Diseño de naves industriales
    • Diseño de tinglados`,
    image: disenhoCalculo,
  },
  {
    name: 'Steel Frame',
    title: 'Especializados en ingeniería de detalle y cálculo estructural de estructuras livianas, utilizamos las últimas tecnologías para asegurar la precisión y eficiencia en cada proyecto de Steel Frame. Empleamos técnicas innovadoras para garantizar altos estándares de calidad y seguridad.',
    image: Steelframe,

  },
  {
    name: 'Metodología BIM',

    title: 'Utilizamos metodología Building Information Modeling (BIM) para integrar todas las fases del proyecto en un modelo digital, facilitando la colaboración y mejorando la planificación y gestión. Nos permite coordinar todas las disciplinas y fases del proyecto en un solo entorno virtual, permitiendo una visualización clara y precisa.',
    image: metodBim,
  },
];

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: 'hidden', background: 'transparent', borderRadius: '50%' }}
    onClick={onClick}
  />
);

const services = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          className: "center sm:pl-20",
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true,
          className: "center",
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true,
          className: "center",
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <div id="Services" className='services-carousel bg-[#00040D] bg-[url("assets/Background_Servicios.jpg")] bg-cover bg-center bg-no-repeat h-[1024px] xl:px-[128px] px-0 sm:px-5 md:px-5 lg:px-10 w-full relative z-0'>
          <style>
        {`
          .services-carousel .slick-prev, 
          .services-carousel .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
          }

          .services-carousel .slick-prev:before, 
          .services-carousel .slick-next:before {
            font-size: 30px;
            color: white;
          }

          @media (max-width: 890px) {
            .services-carousel .slick-prev, 
            .services-carousel .slick-next {
              top: 50%;
              transform: translateY(-50%);
            }

            .services-carousel .slick-prev {
              left: -20px;
            }

            .services-carousel .slick-next {
              right: -10px;
            }
          }
        `}
      </style>
    <div className="absolute inset-x-0 top-0 h-[190px] bg-gradient-to-b from-black to-transparent -z-10"></div>
    <div className="sm:px-20 px-5 bg-transparent mx-auto sm:py-8 py-0">
      <motion.h2 
            initial={{opacity: 0, y:-20}}
            whileInView={{opacity: 1, y:0}}
            viewport={{once: true}}
            transition={{delay: 0.4, stiffness: 100, type:'tween'}}
      className="text-6xl font-bold mb-6 text-center sm:pt-36 pt-24 text-[#FCFCFC] select-none">Nuestros Servicios</motion.h2>
      <motion.p 
            initial={{opacity: 0, y:-20}}
            whileInView={{opacity: 1, y:0}}
            viewport={{once: true}}
            transition={{delay: 0.5, stiffness: 100, type:'tween'}}
      className="text-left sm:pb-36 pb-10 text-[#FCFCFC] max-w-[650px] text-l 2xl:text-xl m-auto leading-tight font-light select-none">
      Nos especializamos en proporcionar soluciones de ingeniería civil con un enfoque en cálculos estructurales y metodología BIM. Nuestro objetivo es ofrecer servicios integrales que aseguren la precisión, eficiencia y sostenibilidad de cada proyecto. Trabajamos en todo el país, brindando un trato personalizado y soluciones adaptadas a las necesidades específicas de estudios de arquitectura y empresas constructoras.</motion.p>
      <motion.div 
            initial={{opacity: 0, y:-20}}
            whileInView={{opacity: 1, y:0}}
            viewport={{once: true}}
            transition={{delay: 0.6, stiffness: 100, type:'tween'}}
      className="hidden lg:block mx-auto w-full 2xl:ml-10 -mt-16">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 mx-auto min-w-316px">
        {teamServices.map((services, index) => (
            <div key={index} className="bg-transparent border-solid	border-[1px] border-[#FCFCFC] text-[#FCFCFC] shadow-lg rounded-lg h-[456px] max-w-[316px] lg:h-[520px] 2xl:h-[456px]">
              <img src={services.image} alt={services.name} title={services.name} className="w-[80px] h-48 rounded-t-lg m-auto -mb-10" />
              <h3 className="text-xl font-semibold m-auto text-center">{services.name}</h3>
              <p className='px-7 m-auto text-left pt-5 font-light leading-tight whitespace-pre-line'>{services.title}</p>
              <p className="px-7 m-auto text-left -mb-3 font-bold leading-tight whitespace-pre-line">{services.education}</p>
              <p className="px-7 m-auto text-left font-light leading-tight whitespace-pre-line">{services.educationText}</p>
            </div>
          ))}

        </div>
      </motion.div>
      <div className="lg:hidden mx-auto w-full sm:px-10 px-5 sm:-mt-20">
      <Slider {...settings}>
          {teamServices.map((services, index) => (
            <div key={index} className="px-5 bg-transparent border-solid	border-[1px] border-[#FCFCFC] text-[#FCFCFC] shadow-lg rounded-lg min-w-[254px] max-w-[254px] h-[440px]">
              <img src={services.image} alt={services.name} title={services.name} className="w-[80px] -mt-10 -mb-10 h-48 rounded-t-lg m-auto" />
              <h3 className="text-xl font-semibold m-auto text-center pb-10">{services.name}</h3>
              <p className='text-sm m-auto text-left font-light leading-tight whitespace-pre-line'>{services.title}</p>
              <p className="text-sm m-auto text-left font-bold whitespace-pre-line">{services.education}</p>
              <p className="text-sm m-auto text-left font-light whitespace-pre-line leading-5 -mt-3">{services.educationText}</p>
            </div>
          ))}
        </Slider>
</div>
    </div>
    <div className="absolute inset-x-0 bottom-0 h-[160px] bg-gradient-to-t from-black to-transparent -z-10"></div>
    </div>
  );
};

export default services;